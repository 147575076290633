import React from 'react'
import Wordmark from '../../static-files/img/wordmark-black.svg'
import Logo from '../../static-files/img/logomark-black.svg'

const Footer = () => (
	<footer className="margintop-xxl">
		<div className="width-100 position-relative paddingtop-xxl bg-white borderwidth-0 bordertopwidth-1 borderstyle-solid bordercolor-grey">
			<div className="wrapper--s">
				<div className="flex-direction--row breakpoint-l--flex-direction--column">
					<div className="display-inlineblock flexgrow-1 breakpoint-l--flexforce-100 breakpoint-m--paddingleftright-s">
						<a className="animsition-link" href="https://invivox.com">
							<img className="display-block marginbottom-l width-50px" src={Logo} alt="Invivox Logo"/>
							<img className="invivox-logo--std display-block marginbottom-l" src={Wordmark} alt="Invivox Wordmark"/>
						</a>
					</div>
					<div className="display-inlineblock">
						<div className="flex-direction--row flex-wrap--wrap breakpoint-m--flex-vstart-hstart breakpoint-m--paddingleftright-s">
							<div className="marginbottom-xxxl paddingright-m width-360 breakpoint-m--width-160">
								<span className="display-block width-100 font--gentium fontsize-m c-black marginbottom-m">Magazine</span>
								<div className="display-block width-100 maxwidth-480">
									<span className="font--r fontsize-s c-black lineheight-1_22">Invivox Magazine is an online blog specifically designed for the medical community. Every week, discover brand new content from experts in their field, stay up to date on innovating tools and cutting-edge techniques. Learn more about medical mentoring opportunities, a cornerstone of knowledge transmission, and discover peers' testimonials.</span>
								</div>
							</div>
							<div className="marginbottom-xxxl paddingright-m width-180 breakpoint-m--width-160">
								<span className="display-block marginbottom-s font--sb fontsize-sm c-black">Company</span>
								<a target="_blank" rel="noopener noreferrer"  className="animsition-link" href="http://invivox.com">
									<span className="display-block marginbottom-s font--r fontsize-xs link link-dark">invivox.com</span>
								</a>
								<a target="_blank" rel="noopener noreferrer"  className="animsition-link" href="mailto:valerie@invivox.com">
									<span className="display-block marginbottom-s font--r fontsize-xs link link-dark">Contact us</span>
								</a>
								<a target="_blank" rel="noopener noreferrer"  className="animsition-link" href="https://invivox.com/cgu">
									<span className="display-block marginbottom-s font--r fontsize-xs link link-dark">Terms &amp; conditions</span>
								</a>
								<a target="_blank" rel="noopener noreferrer"  className="animsition-link" href="https://www.welcometothejungle.co/companies/invivox/jobs">
									<span className="display-block marginbottom-s font--r fontsize-xs link link-dark">Jobs</span>
								</a>
							</div>
							<div className="marginbottom-xxxl paddingright-m width-180 breakpoint-m--width-160">
								<span className="display-block marginbottom-s font--sb fontsize-sm c-black">Social</span>
								<div className="flex-direction--row flex-wrap--wrap">
									<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Invivox-1061888523840431/?fref=ts">
										<span className="display-block inv-icon icon-facebook-square fontsize-xl c-black marginright-s marginbottom-m"></span>
									</a>
									<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Invivox">
										<span className="display-block inv-icon icon-twitter fontsize-xl c-black marginright-s marginbottom-m"></span>
									</a>
									<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/invivox?report%2Esuccess=KJ_KkFGTDCfMt-A7wV3Fn9Yvgwr02Kd6AZHGx4bQCDiP6-2rfP2oxyVoEQiPrcAQ7Bf">
										<span className="display-block inv-icon icon-linkedin fontsize-xl c-black marginright-s marginbottom-m"></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
)

export default Footer