import React from 'react'
import Helmet from 'react-helmet'
import Footer from './Footer'
import Header from './Header'
import Transition from './transition'
import '../static-files/css/style.css'

const Layout = ({ children, location }) => (
<div>
  <Helmet>
  	<meta name="title" content="Invivox Magazine" />
    <title itemProp="name" lang="fr">Invivox Magazine</title>
    <meta name="description" content="Invivox Magazine" />
  </Helmet>
  <Header location={ location } />
    <div>
      <Transition location={ location }>{ children }</Transition>
    </div>
  <Footer />
</div>


)

export default Layout