import React from 'react'
import { Link } from 'gatsby'
import Wordmark from '../../static-files/img/wordmark-black.svg'

const Header = (location) => (
    <header>
        <div className="section-header">
            <div className="section-header--menu">
                <div className="container wrapper--s">
                    <div className="flex-direction--row flex-vcenter width-100 height-70 position-relative">
                        <div className="display-inlineblock z-index--12">
                            <Link className="animsition-link" to="/">
                                <img className="invivox-logo--std" src={Wordmark} alt=""/>
                            </Link>
                        </div>
                        <div className="absolute-logo">
                            <div className="flex-vcenter-hcenter width-100 height-70 position-relative breakpoint-m--justifycontent-start">
                                <span className="font--gentium fontsize-xl c-black">Magazine</span>
                            </div>
                        </div>
                        <div className="display-inlineblock flexalign--right"></div>
                        {/*<div className="display-inlineblock marginleft-m z-index--12">
                            <Link className="animsition-link" to="/search/">
                                <span className="inv-icon icon-search fontsize-l c-black display-block"></span>
                            </Link>
                        </div>*/}
                        <div className="display-inlineblock marginleft-m z-index--12">
                            <a target="_blank" rel="noopener noreferrer" href="https://invivox.com">
                                <button className="button button--m bg-purple">
                                    <span className="font--r fontsize-s font--sb c-white">Get started</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="section-header--submenu">
                <div className="container position-relative">
                    <div className="submenu-links breakpoint-m--displaynone">
                        <div className="width-100 position-relative height-40 flex-vcenter-hcenter">
                            <a className="animsition-link" href="/category/training-room/">
                                <span className={ location.pathname === "/category/training-room/" ? 'active' : '' } >Training Room</span>
                            </a>
                            <a className="animsition-link" href="/category/tech-and-innovations/">
                                <span className={ location.pathname === "/category/tech-and-innovations/" ? 'active' : '' } >Tech & innovations</span>
                            </a>
                            <a className="animsition-link" href="/category/peer-testimonials/">
                                <span className={ location.pathname === "/category/peer-testimonials/" ? 'active' : '' } >Peer Testimonials</span>
                            </a>
                            <a className="animsition-link" href="/category/news/">
                                <span className={ location.pathname === "/category/news/" ? 'active' : '' } >News</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>*/}
        </div>
    </header>
)

export default Header