import React, { Component } from 'react'
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const timeout = 250

class Transition extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  render() {
    const { location } = this.props
    return (
      <div location={ location.pathname }>
        <TransitionGroup>
          <ReactTransition
            key={location.pathname}
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
          >
            {status => (
              <div className={`js-page-transition ${ status }`}>
                {this.props.children}
              </div>
            )}
          </ReactTransition>
        </TransitionGroup> 
      </div>
    )
  }
}

export default Transition
